import { isEmpty } from 'lodash';
import { rootState } from 'models/RootState';
import { solayAPI } from './SolayAPI';

export class Brand {
    public static setup(subdomain: string | null): void {
        solayAPI
            .loadBrands()
            .then((response: any) => {
                const brandDetails = response.data.data.find((b: any) => b.subdomain === subdomain);
                if (isEmpty(brandDetails)) {
                    alert('Unable to find the ' + subdomain + ' resort.');
                } else {
                    rootState.setBrand(brandDetails);
                }
            })
            .catch((error: any) => {
                alert('Unable to query resort.');
            });
    }
}
